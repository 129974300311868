@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

html {
    @apply scroll-smooth bg-primary-700;
}

body {
    @apply bg-primary-25 antialiased;
}

li::marker {
    @apply text-primary-500;
}

.text-primary {
    @apply text-primary-500;
}

.ring-primary {
    @apply ring-primary-500;
}

.bg-primary {
    @apply bg-primary-500;
}

#__next,
main {
    @apply h-full;
}
